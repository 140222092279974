.muni-statusmsg {
  overflow: hidden;
  background-color: #000;
  display: flex;
  transition: all 0.3s;
  padding: 20px;
  border-radius: 10px;
  margin-left: auto;
  color: #fff;
  margin: auto;
  max-width: 450px;
  bottom: -100px;
  position: relative;
}
