.backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  display: none;
}

.muni-page {
  padding: 0 max((calc((100vw - 1350px) / 2)), 0px);
  z-index: 20;
  position: relative;
  height: 100vh;
  overflow: auto;
}
.muni-vvname {
  color: #4a4a4e;
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
}
.muni-logo {
  width: 80px;
  padding: 10px;
  height: auto;
  margin-right: 8px;
}
.muni-logo-group {
  display: flex;
  align-items: center;
}
.muni-toppanel {
  background: rgba(193, 193, 247, 15%);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  margin: 20px;
  padding: 30px;
  flex-flow: row;
  position: relative;
  justify-content: space-between;
}
.muni-fixed {
  position: fixed;

  width: calc(100% - 40px);
  top: -20px;
  border-radius: 0 0 10px 10px;
  opacity: 0;
  height: fit-content;
}

.muni-logout {
  text-decoration: none;
  color: #4a4a4e;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.muni-cupon {
  background-color: #804c804d;
  font-weight: 400;
  padding: 1px 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.muni-logout-button {
  display: flex;
  align-items: center;
  color: #4a4a4e;
  cursor: pointer;
}
.muni-topgroup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.muni-topgroup-activity {
  min-height: 0px;
  background-color: #7a527a63;
  padding: 10px;
  margin: -10px;
  border-radius: 10px;
  flex-grow: 1;
  max-width: 33%;
}
.muni-topgroup-minwidth {
  width: 330px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.loading {
  position: absolute;
  background-color: rgb(245, 247, 246);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  transition: all 1.2s;
}

.muni-grouptitle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a4a4e;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
}
.muni-activityaction {
  color: #4a4a4e;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
}

.muni-linkToAppButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.muni-activitytarget {
  color: #4a4a4e;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
}
.muni-articles {
  display: flex;
  flex-wrap: wrap;
}
.muni-categorygroup {
  display: flex;
  justify-content: center;
  margin: 10px 20px;
  margin-bottom: 30px;
  gap: 10px;
  flex-wrap: wrap;
}
.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -10px;
  margin-right: 20px;
  margin-top: 10px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  display: flex;
  margin-bottom: 30px;
}

.my-masonry-grid_column > div {
  display: none;
}

.my-masonry-grid_column > div:nth-child(-n + 6) {
  display: block;
}

.my-masonry-grid.single-article .my-masonry-grid_column {
  flex: 0 0 100%;
}

.show-more-button {
  cursor: pointer;
  padding: 10px 20px;
  background-color: rgb(255 104 99);
  color: white;
  border: none;
  border-radius: 5px;
  margin: 20px auto;
  display: block;
}

.muni-categorygroup select {
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s;
}

.muni-categorygroup select:hover,
.muni-categorygroup select:focus {
  border-color: rgba(255, 104, 99, 0.3);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.muni-categorygroup select option {
  padding: 10px;
}

.muni-categorygroup select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%227%22%20viewBox%3D%220%200%2012%207%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M1%201L6%206L11%201%22%20stroke%3D%22%23333%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
}

.muni-flagGroup-panelPage-mobile {
  display: none;
}

@media (max-width: 968px) {
  .muni-toppanel {
    flex-flow: column;
    gap: 40px;
  }
  .hide-logo .muni-intro-landingIntro-logo {
    display: none;
  }

  .muni-flagGroup-panelPage-mobile {
    display: block;
  }

  .muni-flagGroup-panelPage-desktop {
    display: none;
  }
  .muni-topgroup-minwidth {
    width: 100%;
  }
  .muni-fixed {
    opacity: 1;
  }
  .muni-topgroup-activity {
    min-height: 0px;
    min-width: fit-content;
  }

  .my-masonry-grid_column {
    width: 30vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

@media (max-width: 760px) {
  .muni-toppanel {
    flex-flow: column;
    gap: 40px;
    margin: 0;
    padding: 0;
  }
  .muni-topgroup-minwidth {
    width: 100%;
  }
  .muni-fixed {
    opacity: 1;
  }
  .muni-topgroup-activity {
    min-width: 100%;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }

  .my-masonry-grid_column {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .muni-topgroup-progressbar {
    display: none;
  }

  .muni-category-mobile::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .muni-category-mobile {
    position: sticky;
    top: 0;
    z-index: 1;

    background-color: #fff;
    min-height: 7rem;
  }

  .muni-category-mobile img {
    padding: 0;
    margin: 0;
  }
}
