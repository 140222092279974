

.muni-landing-intro-page-3 {
  box-sizing: border-box;
  background-image: url("/src/images/landingDadChild2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: stretch;
  align-items: center;
}

.muni-landing-intro-container-3 {
  min-width: 100vw;
  padding: 0px 32px 20px 32px;
  color: white;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  text-align: left;
  position: relative;
}

.muni-intro-tagline-h1-3 {
  font-size: 30px;
  font-weight: 500;
  max-width: 75vw;
  font-weight: 600;
  margin: 0;
}

.muni-intro-button-container {
  max-width: 750px;
}

.muni-intro-button-container h2{
  font-weight: 200;
  font-size: 25px;
  line-height: 1.2;
    margin: 0;

}

.muni-intro-button {
  appearance: none;
  border-radius: 50px;
  border: none;
  background-color: #f65a55;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  -webkit-user-select: "none";
  padding: 15px 20px 15px 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: metropolis;
}

.muni-intro-button-professionell {
  box-sizing: border-box;
  appearance: none;
  border-radius: 50px;
  padding: 13px 18px 13px 18px;
  font-size: 16px;
  font-weight: 500;
  font-family: metropolis;
  border-radius: 50px;
  border: 1.5px solid transparent;
  background-color: transparent;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  cursor: pointer;
  transition: border-color 1s ease;
  -webkit-user-select: "none";
}

.muni-intro-button-professionell:hover {
  border-color: white;
  background-color: rgba(255, 255, 255, 0.2);
}

@media (min-width: 390px) {
  .muni-intro-tagline-h1-3 {
    font-size: 35px;
    font-weight: 600;
    max-width: 80vw;
  }

  .muni-intro-button {
    padding: 18px 26px 18px 26px;
    font-size: 16px;
  }

  .muni-intro-button-professionell {
    padding: 15px 24px 15px 24px;
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .muni-landing-intro-page-3 {
    /* min-height: 60vh; */
  }

  .muni-landing-intro-container-3 {
    padding: 50px 50px 0px 50px;
  }

  .muni-intro-tagline-h1-3 {
    font-size: 40px;
    font-weight: 600;
    max-width: 80vw;
  }

  .muni-intro-button-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .muni-intro-button {
    padding: 15px 24px 15px 24px;
    font-size: 16px;
    font-weight: 500;
    font-family: metropolis;
  }

  .muni-intro-button-professionell {
    padding: 13px 24px 13px 24px;
    font-size: 16px;
    font-weight: 500;
    font-family: metropolis;
  }
}

@media (min-width: 968px) {

  .muni-intro-tagline-h1-3 {
    font-size: 48px;
    font-weight: 500;
    max-width: 60vw;
    font-weight: 600;
  }

  .muni-intro-button-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .muni-intro-landingIntro-logo {
    padding: 0px;
  }

  .muni-arrow-svg-img {
    height: 20px;
  }
}

@media (min-width: 1024px) {
  .muni-intro-tagline-h1-3 {
    font-size: 48px;
    font-weight: 500;
    max-width: 550px;
    font-weight: 600;
  }

  .muni-landing-intro-container-3 {
    padding: 90px 90px 0px 90px;
  }
}

@media (min-width: 1280px) {
   .muni-intro-tagline-h1-3 {
    max-width: 800px;
  }

  .muni-intro-button {
  font-size: 18px;
  font-weight: 500;
  padding: 16px 32px 16px 32px;
}

  .muni-intro-button-professionell {
  font-size: 18px;
  font-weight: 500;
  padding: 13px 32px 13px 32px;
}

}

@media (min-width: 1500px) {

  .muni-landing-intro-container-3 {
    padding: 120px 120px 0px 120px;
  }

  .muni-intro-button,
  .muni-intro-button-professionell {
    font-size: 18px;
  }
}
