

.muni-landing-howItWorks {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FDF5F5;
}

.muni-landingInfo-howItWorks-continer {
  display: flex;
  /* align-items: stretch; */
  flex-direction: column;
  padding: 80px 32px 80px 32px;  
  gap: 80px;
  width: 100%;
}

.muni-logo-howItWorks {
  width: 60px;
  display: none;
}

.muni-landingInfo-howItWorks-intro {
  display: flex;
  flex-direction: column;
  gap: 70px;
  line-height: 1.2;
}

.muni-landingInfo-howItWorks-mobile {
  height: 60vh;
  align-self: center;
}

.muni-landing-howItWorks h1 {
  display:  none;
  color: #7a527a;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
}

.muni-landingInfo-howItWorks-intro-title{
  padding: 0px 10px 0px 10px;
}

#muni-landing-howItWorks-subtext-first {
  /* display:  none; */
  text-align: center;
  color: #e26561;
  font-weight: 600;
  font-size: 25px;
}

#muni-landing-howItWorks-subtext-second {
  /* display:  none; */
  color: #e26561;
  font-weight: 200;
  font-size: 25px;
}

#muni-landing-howItWorks-subtext-first-2 {
  text-align: center;
  color: #7a527a;
  font-weight: 600;
  font-size: 25px;
}

#muni-landing-howItWorks-subtext-second-2 {
  font-weight: 200;
    font-size: 25px;
}

.muni-landingInfo-howItWorks-continer-parts {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0px 10px 0px 10px;;
  }

.muni-landingInfo-howItWorks-parts {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.muni-landingInfo-howItWorks-parts h3 {
  color: #e26561;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
}

.muni-landingInfo-howItWorks-parts p {
  text-align: left;
  font-size: 16px;
  max-width: 77vw;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .muni-landingInfo-howItWorks-intro-title{
  padding: 50px 50px 50px 50px;
}

.muni-landingInfo-howItWorks-continer-parts {
  flex-direction: row;
  justify-content: space-evenly;
  gap: 40px;
  padding: 0px 10px 0px 10px;;
  }

#muni-landing-howItWorks-subtext-first, #muni-landing-howItWorks-subtext-second, #muni-landing-howItWorks-subtext-first-2, #muni-landing-howItWorks-subtext-second-2  {
  font-size: 30px;
}

.muni-landingInfo-howItWorks-intro {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.muni-landingInfo-howItWorks-parts p {
  text-align: left;
  font-size: 15px;
  max-width: 750px;
}

}

@media (min-width: 1024px) {

.muni-landingInfo-howItWorks-continer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 150px 90px 150px 90px;  
  gap: 100px;
}

.muni-landingInfo-howItWorks-continer-parts {
  gap: 70px;
}

.muni-landingInfo-howItWorks-parts h3 {
  color: #e26561;
  text-align: left;
  font-size: 22px;
  font-weight: 600;
}

.muni-landingInfo-howItWorks-mobile {
  height: 400px;
  align-self: center;
}

.muni-landingInfo-howItWorks-intro{
  flex-direction: row;
  align-items: center;
}

.muni-landingInfo-howItWorks-intro-title{
  padding: 0 0 50px 0;
}

#muni-landing-howItWorks-subtext-first, #muni-landing-howItWorks-subtext-second, #muni-landing-howItWorks-subtext-first-2, #muni-landing-howItWorks-subtext-second-2  {
  text-align: left;
}

#muni-landing-howItWorks-subtext-first-2 {
 order: 2;
}
#mobile-2{
  order: 1;
}
}

@media (min-width: 1280px) {

.muni-landingInfo-howItWorks-continer {
  align-items: stretch;
}

.muni-landingInfo-howItWorks-intro {
  justify-content: flex-start;
  width: 100%;
  gap: 70px;
}

.muni-landingInfo-howItWorks-continer {
  max-width: 1400px;
  padding: 150px 90px 150px 90px;
  gap: 120px; 
}

.muni-landingInfo-howItWorks-intro-title{
  max-width: 780px;
}

.muni-landingInfo-howItWorks-continer-parts {
  padding: 0px;
  gap: 70px;
  justify-content: space-between;
}

.muni-landingInfo-howItWorks-mobile {
  height: 430px;
}

#muni-landing-howItWorks-subtext-first, #muni-landing-howItWorks-subtext-second, #muni-landing-howItWorks-subtext-first-2, #muni-landing-howItWorks-subtext-second-2  {
  font-size: 30px;
}

.muni-landingInfo-howItWorks-parts p {
    max-width: 270px;
}

#mobile-2 {
  height: 450px;
  margin-left: -30px;
}
}

@media (min-width: 1500px) {


  .muni-landingInfo-howItWorks-continer {
    max-width: 1400px;
    gap: 190px;
  }

  .muni-landingInfo-howItWorks-parts p {
  font-size: 16px;
  }
}

