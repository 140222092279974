

.muni-intro-experts {
  background-color: #FDF5F5;
  display: flex;
  min-height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.muni-intro-experts-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 32px 80px 32px;
  gap: 50px;
}

.muni-intro-experts-intro {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 750px;
  align-self: flex-start;
}

.muni-intro-experts-title {
  /* text-align: center; */
  font-size: 30px;
  font-weight: 600;
  color: #794E7D;
}

.muni-intro-experts-subtitle {
  /* text-align: center; */
  font-size: 16px;
  font-weight: 200;
  line-height: 1.2;
}

.muni-intro-experts-link{
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  max-width: fit-content;
    border-bottom: transparent 2px solid;

}

.muni-intro-experts-a{
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: #794E7D;
}

.muni-intro-experts-link:hover {
  border-bottom: #794E7D 2px solid;
}

.muni-intro-experts-parts-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
}

.muni-intro-experts-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
}

.muni-intro-experts-part-text {
  display: flex;
  flex-direction: column;
  gap: 20px;;
}

.muni-intro-experts-part-text h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
}

.muni-intro-experts-part-text h2 {
font-size: 14px;
font-weight: 500;
    text-transform:uppercase;

}

.muni-intro-experts-part p {
  font-size: 16px;
  line-height: 1.2;
}

.muni-intro-experts-part img {
  border-radius: 50%;
  height: 150px;
  width: 150px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.muni-intro-experts-parts-citat {
  background-color: #EAB3B2;
  padding: 20px;
}



@media (min-width: 768px) {
.muni-intro-experts-container {
    padding: 150px 90px 150px 90px;
    max-width: 1400px;
    gap: 80px;
}

.muni-intro-experts-part {
  flex-direction: row;
  padding: 40px 0px 40px 0px;
  gap: 60px;
}

.muni-intro-experts-parts-container{
  flex-direction: column;
  gap: 80px;
} 

.muni-intro-experts-part-text {
  text-align: left;
}

  .muni-intro-experts-title {
    font-size: 35px;
    font-weight: 600;
  }

  .muni-intro-experts-part img {
  border-radius: 50%;
  height: 200px;
  width: 200px;
}
}


@media (min-width: 1024px) {
  .muni-intro-experts-title {
    font-size: 42px;
  }
}

@media (min-width: 1280px) {

.muni-intro-experts-title {
    max-width: 750px;
  }

.muni-intro-experts-container {
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
  padding: 150px 90px 150px 90px;
  gap: 80px;
  flex-grow: 2;
}

.muni-intro-experts-parts-container {
    flex-direction: row;
}

.muni-intro-experts-part {
  flex: 1;
  flex-direction: column;
}

.muni-intro-experts-part-text {
  text-align: center;
}
}

@media (min-width: 1500px) {

}


