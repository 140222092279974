.muni-articlepage {
  width: 100%;
  height: 100%;
  z-index: 30;
  position: fixed;
  overflow: hidden;
  background-color: rgb(0 0 0 / 0%);
  display: flex;
  transition: all 0.3s;
}

.exercise-badge {
  width: 24px;
  height: auto;
  position: absolute;
  top: 6px;
  right: 6px;
  opacity: 1;
}

.muni-articleholder:hover .exercise-badge.has-exercise {
  animation: shakeIcon 0.5s infinite;
}

@keyframes shakeIcon {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(40deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.muni-bigarticle-card {
  background-color: #fff;
  display: inline-flex;
  overflow: hidden;
  left: 100vw;
  position: relative;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  border-radius: 4px;
  transition: all 0.3s;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
}
@media screen and (max-width: 850px) {
  .muni-bigarticle-card {
    border-radius: 0;
    max-height: 100vh;
    height: 100vh;
    margin: 0px;
  }
}
.muni-bigarticle-close {
  position: absolute;
  background-color: rgb(255 104 99);
  right: 25px;
  top: 25px;
  border-radius: 101px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.muni-bigarticle-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px;
  overscroll-behavior: none;
}
.muni-bigarticle-image {
  background-color: #fff;
  width: 100%;
  min-height: 44px;
  background-repeat: no-repeat;
  background-size: cover;
}
.muni-bigarticle-special-image {
  margin: -20px;
  width: calc(100% + 40px);
  margin-bottom: 0px;
  min-height: 30vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.muni-bigarticle-done {
  float: right;
  width: 60px;
  height: 60px;
  cursor: pointer;
  position: relative;
}
.muni-bigarticle-done-placeholder {
  float: right;
  width: 60px;
  height: 60px;
}

.muni-checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #65b46d;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.muni-checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #65b46d;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.muni-checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #65b46d;
  }
}

.muni-exercise-title-block {
  min-height: auto;
  padding: 20px;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  text-shadow: 0px 0px 7px black;
  margin-top: 20px;
}

.muni-reflections h2 {
  font-size: 18px;
  color: #4a4a4e;
}

.muni-bigarticle-content h1 {
  font-size: 24px;
  color: #4a4a4e;
  text-transform: uppercase;
}

.muni-bigarticle-content h2 {
  font-size: 20px;
  color: #4a4a4e;
}

.muni-bigarticle-content h3 {
  font-size: 20px;
  color: #4a4a4e;
}
.muni-bigarticle-content p {
  font-size: 18px;
  line-height: 20px;
  color: #4a4a4e;
}

.wp-block-pullquote {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: 600;
}
