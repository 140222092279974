/*BODY*/
.page-landing-container {
  min-height: 100vh;
  width: 100vw;
  /* overflow: auto; */
  color: #222222;
  display: flex;
  flex-direction: column;
  position: relative;
}

div {
  scroll-behavior: smooth;
}

section {
  scroll-snap-align: start;
}

/*header*/

.page-landing-header {
  min-width: 100vw;
  position: absolute;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px 0px 15px;
}

.muni-intro-landingIntro-logo {
  height: 40px;
}

.page-landing-header-right-part {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.page-landing-header-right-part-button {
  appearance: none;
  border-radius: 50px;
  border: 1.5px solid white;
  text-align: center;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  padding: 13px 18px 13px 18px;
  font-size: 16px;
  font-weight: 500;
  font-family: metropolis;
}

/*main*/
.page-landing-main {
  flex: 1;
  max-height: 100vh;
  overflow-y: scroll;
  position: relative;
  scroll-snap-type: y;
}

.muni-header-in-landingPage {
  background-color: aqua;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
}

.muni-landingExperts-headline {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f58a81;
  font-size: 23px;
}

.hide-on-menu-open {
  display: none;
}

.muni-intro-divider {
  height: 50px;
  width: 100%;
  background-color: #ffffff;
  clip-path: polygon(
    100% 100%,
    0% 100%,
    0% 98%,
    2% 97.15%,
    4% 94.63%,
    6% 90.53%,
    8% 84.99%,
    10% 78.21%,
    12% 70.44%,
    14% 61.94%,
    16% 53.01%,
    18% 43.98%,
    20% 35.17%,
    22% 26.88%,
    24% 19.4%,
    26% 13.02%,
    28% 7.94%,
    30% 4.35%,
    32% 2.38%,
    34% 2.09%,
    36% 3.51%,
    38% 6.57%,
    40% 11.17%,
    42% 17.14%,
    44% 24.28%,
    46% 32.33%,
    48% 41.01%,
    50% 50%,
    52% 58.99%,
    54% 67.67%,
    56% 75.72%,
    58% 82.86%,
    60% 88.83%,
    62% 93.43%,
    64% 96.49%,
    66% 97.91%,
    68% 97.62%,
    70% 95.65%,
    72% 92.06%,
    74% 86.98%,
    76% 80.6%,
    78% 73.12%,
    80% 64.83%,
    82% 56.02%,
    84% 46.99%,
    86% 38.06%,
    88% 29.56%,
    90% 21.79%,
    92% 15.01%,
    94% 9.47%,
    96% 5.37%,
    98% 2.85%,
    100% 2%
  );
}

.muni-flag-mobile-hide {
  display: none;
}

.navigation-dots {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 8px 8px;
  background-color: rgba(0, 0, 0, 0.333);
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hidden {
  display: none;
}

.dot.active {
  background-color: white;
}

/*Ipad mini*/
@media (min-width: 768px) {
  .muni-intro-landingIntro-logo {
    height: 47px;
  }
  .page-landing-header {
    padding: 30px 30px 0px 30px;
  }

  .hamburger-menu {
    height: 50px;
    cursor: pointer;
  }

  .page-landing-header-right-part-button {
    padding: 13px 24px 13px 24px;
    font-size: 16px;
    font-weight: 500;
    font-family: metropolis;
  }
}

/*oklart vilken device*/
@media (min-width: 968px) {
}

/*Ipad pro & liten laptop*/
@media (min-width: 1024px) {
  .muni-intro-landingIntro-logo {
    height: 50px;
  }
  .page-landing-header {
    padding: 30px 30px 0px 30px;
  }

  .hamburger-menu {
    height: 50px;
  }
}

/*laptop*/
@media (min-width: 1280px) {
.page-landing-header-right-part-button { 
  font-size: 18px;
  font-weight: 500;
  padding: 13px 32px 13px 32px;
  }
}

/*Större skärm*/
@media (min-width: 1500px) { 
  .muni-intro-landingIntro-logo {
    height: 60px;}

  .page-landing-header {
        padding: 30px 40px 0px 40px;

  }
  

  .muni-intro-landingIntro-logo {
    height: 60px;
  }

  .page-landing-header {
    padding: 30px 40px 0px 40px;

  }

  .muni-intro-landingIntro-logo {
    height: 60px;
  }

  .hamburger-menu {
    height: 55px;
  }

  }

@media only screen and (min-width: 1536px) {
  .muni-landingInfo-bigScreens {
    display: grid;
    height: 100vh;
    place-items: center;
  }

  .muni-flag-mobile-hide {
    display: block;
  }
}
