
.muni-intro-next-step {
  background-color: #F4EDE7;
  display: flex;
  min-height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 90px;
  width: 100vw;
}

.muni-intro-next-step-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;
  background-color: #F4EDE7;
  padding: 80px 32px 80px 32px;

}

.muni-intro-next-step-headline {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  max-height: fit-content;
  align-items: center;
  justify-content: center;
  gap: 40px;
  max-width: 100vw;
  font-size: 25px;
  margin-top: 0;
}

.muni-intro-next-step-headline-quotes {
  display: flex;
  font-size: 20px;
  text-align: center;
  gap: 90px;
}

.muni-intro-next-step-headline-quotes-2 {
  display: none;
}

.muni-intro-next-step-headline-second {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.muni-intro-next-step-section-item {
  display: flex;
  align-items: center;
}

.muni-intro-next-step-text-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: left;
  align-items: flex-start;
}

.muni-intro-next-step-text-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.muni-intro-next-step-headline-ipad-phone, .muni-intro-next-step-headline-ipad {
  
  display: none;
}

.muni-intro-next-step-headline-phone {
  width: 70vw;
  align-self: center;
}

.muni-intro-next-step-headline-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  text-align: center;
}

.muni-intro-next-step-headline h3 {
  font-size: 30px;
  font-weight: 600;
  color: #794E7D;
}

.muni-intro-next-step-headline-content p {
  width: 95%;
  font-size: 16px;
  line-height: 1.2;
}

.muni-intro-next-step-button-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.muni-intro-next-step-app-button{
  align-self: center;
  appearance: none;
  border-radius: 50px;
  border: none;
  background-color: #f65a55;
  text-align: center;
  color: #ffffff;
  padding: 15px 20px 15px 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: metropolis;
}

.muni-intro-next-step-site-button{
  box-sizing: border-box;
  appearance: none;
  border-radius: 50px;
  padding: 12px 23px 12px 23px;
  border-radius: 50px;
  border: 1.5px solid transparent;
  background-color: transparent;
  color: #f65a55;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  font-family: metropolis;
  transition: border-color 1s ease;
}

.muni-intro-next-step-site-button:hover {
  border-color: #f65a55;
  background-color: rgba(223, 97, 88, 0.1);
  /* background-color: rgba(121, 78, 125, 0.1); */
}


@media (min-width: 768px) {

.muni-intro-next-step-headline h3 {
  font-size: 35px;
  font-weight: 600;
  color: #794E7D;
}

.muni-intro-next-step-container {
  padding: 100px 90px 100px 90px;
  gap: 100px;
}

.muni-intro-next-step-headline {
  flex-direction: column;
  gap: 90px;
}

.muni-intro-next-step-headline-phone {
  display: none;
}

.muni-intro-next-step-headline-ipad-phone {
  display: inline-block;
  max-width: 100%;
  align-self: center;
}

.muni-intro-next-step-button-container {
  flex-direction: row;
  gap: 20px;
}

}

@media (min-width: 1024px) {



.muni-intro-next-step-text-list {
  gap: 15px;
}
  .muni-intro-next-step-container {
  padding: 100px 90px 100px 90px;
  gap: 100px;
}

.muni-intro-next-step-headline h3 {
  font-size: 42px;
  font-weight: 600;
  color: #794E7D;
}

.muni-intro-next-step-headline-content {
  gap: 40px;
}
}


@media (min-width: 1280px) {
.muni-intro-next-step-container {
padding: 150px 90px 150px 90px;  
gap: 100px;
max-width: 1400px;
}

.muni-intro-next-step-headline h3 {
  font-size: 42px;
  font-weight: 600;
  color: #794E7D;
}

.muni-intro-next-step-headline-ipad {
  display: inline-block;
  width: 350px;
}  

.muni-intro-next-step-headline {
  flex-direction: row;
  gap: 40px;
}

.muni-intro-next-step-headline-content{
align-items: flex-start;
 text-align: left;
}

.muni-intro-next-step-headline-ipad-phone {
  display: none;
}
}

@media (min-width: 1500px) {

  .muni-intro-next-step-container {
  padding: 150px 90px 150px 90px;
  gap: 150px;
}

.muni-intro-next-step-headline-ipad {
  display: inline-block;
  width: 380px;
}  


.muni-intro-next-step-app-button{
    padding: 16px 30px 16px 30px;
    font-size: 18px;
}

.muni-intro-next-step-site-button{
    padding: 13px 30px 13px 30px;
    font-size: 18px;
}
    

}


