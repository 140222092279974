
.menu-hamburger {
  display: flex;
  align-items: center;
  justify-content:flex-start;
  flex-direction: column;
  z-index: 1000;
  background-color: #F4EDE7;
  height: 100vh;
  position: fixed;
  right: 0;
}

.menu-close-icon {
  cursor: pointer;
  align-self: flex-end;
  margin: 25px;
}

.menu-hamburger-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 20px 32px 64px 32px;;  
  gap: 10vh;
  width: 100vw;
  overflow: scroll;
}

.menu-options-container{
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.testar {
   display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 60px;
}

.menu-option  {
 /* border: 2px solid green; */
 display: flex;
 flex-direction: column;
 gap: 10px;
}

.menu-option.menu-open {
  background-color: transparent;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 15px;
}

.menu-option.menu-closed {
  background-color: transparent;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 15px;
}

.menu-option-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding:10px 10px 10px 15px;
}

.menu-option-title h3 {
  font-size: 17px;
  cursor: pointer;
  font-weight: 600;
}   

.menu-option-dropDown-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  background-color: white;
  padding:20px;
  border-radius: 10px;
  animation: slideUp 1.2s forwards;
}

.arrow-down {
  transform: rotate(0deg);
  transition: transform 0.5s ease;
  cursor: pointer;
}

.arrow-up {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
  cursor: pointer;
}

.menu-option-dropDown-container h2 {
  font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
}

.menu-option-dropDown-container p {
  font-size: 15px;
}

.menu-option-dropDown-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  gap: 10px;
}

.menu-option-dropDown-section h3 {
  font-size: 16px;
  font-weight: 600;
}

.menu-hamburger-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}


.menu-hamburger-login-button {
  appearance: none;
  border-radius: 50px;
  border: none;
  background-color: #f65a55;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  padding: 15px 30px 15px 30px;
  font-size: 16px;
  font-weight: 500;
  font-family: metropolis;
}

.menu-hamburger-create-account-button{
 text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  border: none;
  background: none;
  color: #df6158;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}


@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.menu-hamburger.entering,
.menu-hamburger.open {
  animation: slideIn 1.2s forwards;
}

.menu-hamburger.exiting {
  animation: slideOut 1.2s forwards;
}

.link-to-app {
  text-decoration: underline;
}

@media (min-width: 768px) {

.menu-close-icon {
  cursor: pointer;
  align-self: flex-end;
  margin: 30px;
  height: 38px;
}

.menu-options-container {
  align-self: center;
  width: 350px;
}

.menu-hamburger-container {
  gap: 8vh;
  padding: 100px 40px 100px 40px;
  width: 100%;
}


}

@media (min-width: 1024px) {

.menu-option-title h3 {
  font-size: 18px;
}  

.menu-option-dropDown-container h2 {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
}

.menu-option-dropDown-container h3 {
  font-size: 16px;
}

.menu-option-dropDown-container p {
  font-size: 16px;
}

.menu-hamburger-container {
  gap: 8vh;
  padding: 120px 40px 120px 40px;
  width: 100%;
}

.menu-options-container {
  align-self: center;
  width: 350px;
}
}

