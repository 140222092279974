.muni-articleholder {
  transition: all 0.3s;
  position: relative;
}
.muni-articleholder:hover {
  transform: scale(1.03);
}
.muni-articleholder:active {
  transform: scale(0.98);
}
.muni-readlogo {
  background-color: #65b46d;
  position: absolute;
  width: 28px;
  height: 28px;
  right: 10px;
  bottom: 10px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.muni-readlogo-check {
  display: inline-block;
  transform: rotate(45deg);
  height: 16px;
  width: 9px;
  border-bottom: 3px solid #ffffff;
  top: -2px;
  position: relative;
  border-right: 3px solid #ffffff;
}
.muni-articlecard {
  width: 100%;
  cursor: pointer;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
  border-color: #0000000f;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  margin-bottom: 30px;
  box-shadow: 2px 2px 10px 4px rgb(0 0 0 / 5%);
  transition: all 0.3s;
  background-color: #ffffff;
}
.muni-articlecard:hover {
  box-shadow: 8px 8px 10px 8px rgb(0 0 0 / 10%);
}
.muni-articleimage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: auto;
  height: 280px;
}
.muni-articlecontent {
  padding: 16px;
}
.muni-articletitle {
  color: #4a4a4e;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
}
.muni-articletext {
  color: #7b7b83;
  font-size: 16px;

  line-height: 1.5em;
  height: 7em;
  overflow: hidden;
  display: block;
}
.muni-articlereadmore {
  color: #f65a55;
  font-size: 16px;
  line-height: 120%;
}

@media screen and (max-width: 768px) {
  .muni-articletext {
    line-height: 1.5em;
    height: 4em; /* 1.5em * 4 rader */
    font-size: 12px;
    overflow: hidden;
    display: block;
  }
  .muni-articletitle {
    color: #4a4a4e;
    font-size: 12px;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
  }

  .muni-articleimage {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: auto;
    height: 100px;
  }

  .muni-articlecard {
    margin-bottom: 15px;
  }
  .muni-articlecontent {
    padding: 6px;
  }
}
