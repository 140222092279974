/*
.muni-flag-group {
  position: absolute;
  right: 20px;
  top: 2px;
}
*/


.muni-flag {
  width: 35px;
  height: auto;
  cursor: pointer;
  padding: 2px;
}

@media screen and (max-width: 960px) {
  .muni-flag-group {
    position: relative;
  }
}
