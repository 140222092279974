

.muni-login-form-conatiner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.muni-login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding-bottom: 10vh;
}

.muni-signup-header {
  color: white;
  font-size: 20px;
  font-weight: 600;
  font-family: metropolis;
}

.muni-login-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.muni-options-forgot {
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  align-items: center;
  cursor: pointer;
  gap: 20px;
  text-decoration: underline;
}

.muni-options-createAcc {
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  align-items: center;
  cursor: pointer;
  gap: 20px;
}

.muni-options-createAcc span {
  font-weight: 500;
  text-decoration: underline;
}



.error {
  color: #fff;
}

.muni-input {
  font-family: metropolis;
  border: 1px solid #ababab;
  border-radius: 10px;
  box-shadow: inset -1px -1px 23px 0 rgb(0 0 0 / 7%);
  color: #505050;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 300;
  padding: 14px 23px 14px 23px;
  width: 90%;
  min-width: fit-content;
}

.muni-login-register-button {
  font-family: metropolis;
  appearance: none;
  border-radius: 50px;
  padding: 15px 30px 15px 30px;
  border: none;
  background-color: #f65a55;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
}

.muni-register-button {
  font-family: metropolis;
  appearance: none;
  border-radius: 50px;
  padding: 15px 25px 15px 25px;
  border: none;
  background-color: #f65a55;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
}
/* 
.muni-signup-box span {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 18.75px;
  text-align: center;
}

.muni-gotAnAccount {
  margin-top: 15px;
}

.muni-login-label {
  text-decoration: underline;
  padding: 5px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #402640;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 1001;
} */

@media (min-width: 768px) {
  .muni-signup-header {
    font-size: 20px;
  }

  .muni-input {
    width: 45%;
  }
}

@media (min-width: 1024px) {
  .muni-input {
    font-size: 16px;
    max-width: 350px;
    padding: 16px 25px 16px 25px;
  }

  .muni-options-forgot, .muni-options-createAcc {
    font-size: 16px;
  }

  .muni-login-register-button {
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    padding: 16px 32px 16px 32px;
  }
}

@media (min-width: 1500px) {
  .muni-input {
    max-width: 350px;
  }
}
