
.muni-landingForWork {
  background-color: #F4EDE7;
  display: flex;
  min-height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#muni-landingForWork-img-2 {
  display: none;
}

.muni-landingForWork-container {
  display: flex;
  padding: 80px 32px 80px 32px;
}

.muni-landingForWork-section-1 {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.muni-landingForWork-text {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.muni-landingForWork-container img {
  align-self: center;
  max-width: 100%;
}

.muni-landingForWork-container h2 {
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  color: #e26561;
}

.muni-landingForWork-container h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #e26561;
}

.muni-landingForWork-container p {
  font-size: 16px;
  text-align: left;
  line-height: 1.2;
}

.muni-ForWork-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.muni-ForWork-button {
  appearance: none;
  border-radius: 50px;
  border: none;
  background-color: #f65a55;
  text-align: center;
  color: #ffffff;
  padding: 15px 20px 15px 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: metropolis;
}

.muni-contact-us-button {
  box-sizing: border-box;
  appearance: none;
  border-radius: 50px;
  padding: 14px 23px 14px 23px;
  border-radius: 50px;
  border: 1.5px solid transparent;
  background-color: transparent;
  color: #f65a55;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  font-family: metropolis;
  transition: border-color 1s ease;
}

.muni-contact-us-button:hover {
  border-color: #f65a55;
  background-color: rgba(223, 97, 88, 0.1);
}

@media (min-width: 390px) {
  .muni-landingForWork-container h2 {
    font-size: 35px;
    font-weight: 600;
  }
}

@media (min-width: 768px) {
  .muni-landingForWork-container {
    flex-direction: column;
    padding: 150px 90px 150px 90px;
    align-items: center;
    gap: 80px;
  }

.muni-ForWork-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}


  .muni-landingForWork-section-1 {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  #muni-landingForWork-img-1 {
    display: none;
  }

  #muni-landingForWork-img-2 {
    display: block;
    width: 500px;
  }
}

/* @media (min-width: 820px) {
   .muni-landingForWork-container {
    gap: 80px;
}
} */

@media (min-width: 1024px) {
  #muni-landingForWork-img-2 {
    display: inline-flex;
    margin-right: -70px;
  }

  .muni-landingForWork-container {
    flex-direction: row;
    padding: 150px 90px 150px 90px;
    align-items: center;
    max-width: 1400px;
  }

  .muni-landingForWork-container h2 {
    text-align: left;
    font-size: 42px;
    font-weight: 600;
    color: #e26561;
  }
}

@media (min-width: 1280px) {

.muni-ForWork-button {
  padding: 16px 30px 16px 30px;
  font-size: 18px;
}

.muni-contact-us-button {
  padding: 13px 30px 13px 30px;
  font-size: 18px;
}
}

@media (min-width: 1500px) {
  #muni-landingForWork-img-2 {
    width: 550px;
  }
}
