@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-Medium.otf") format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-LightItalic.otf") format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-ExtraLightItalic.otf") format('opentype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-ThinItalic.otf") format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-ExtraLight.otf") format('opentype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-SemiBold.otf") format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-Light.otf") format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-MediumItalic.otf") format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-ExtraBoldItalic.otf") format('opentype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-SemiBoldItalic.otf") format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-Bold.otf") format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-Regular.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-Black.otf") format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-ExtraBold.otf") format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-BoldItalic.otf") format('opentype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-RegularItalic.otf") format('opentype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-Thin.otf") format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url("./fonts/Metropolis-BlackItalic.otf") format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}




html, h1, h2, p {
  font-family: 'Metropolis', sans-serif;
}

html {
  width: 100vw;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

body {
  margin: 0;
}

#root {
  position: relative;
  overflow: hidden;
}

/* Normalize paddings: https://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
* {
  box-sizing: border-box;
}

button, a, link {
  cursor: pointer;
}


