.muni-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.muni-progress-figure {
  width: 80px;
  height: 100px;
  position: relative;
}
.muni-progress-label {
  color: #4a4a4e;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  text-align: center;
}
.muni-outer {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
  padding: 10px;
}

.muni-inner {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.17),
    -0.5px -0.5px 0px rgba(255, 255, 255, 0.1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 12px 10px -10px rgba(0, 0, 0, 0.05);
  font-weight: 600;
  color: #4a4a4e;
  display: flex;
  align-items: center;
  justify-content: center;
}
circle {
  fill: none;
  stroke-width: 10px;
  stroke: url(#GradientColor);
  stroke-dasharray: 220;
  stroke-dashoffset: 220;
  transition: stroke-dashoffset 2s;
}
svg {
  position: absolute;
  top: 0;
  left: 0;
}
.muni-progress-dot {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: #4a4a4e;
}
