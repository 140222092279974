

.muni-intro-article-amount {
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #794E7D;
}

.muni-intro-article-amount-conatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 32px 80px 32px;
  gap: 50px;
  width: 100%;
}    

.muni-intro-articles {
  width: 100%;
  height: 300px;
  border-radius: 15px;
  padding: 18px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;}

.muni-intro-article-text {
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  color: #794E7D;
}

.muni-intro-article-text h2 {
  font-size: 40px;
  font-weight: 500;
}

.muni-intro-article-text p {
  font-size: 20px;
}

/* .muni-intro-articles img {
  width: 100px;
  height: 150px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
} */


@media (min-width: 768px) { 

  .muni-intro-article-amount-conatiner {
    flex-direction: row;
    justify-content: space-between;
  }    
  
  .muni-intro-articles {
    height: 200px;
    width: 200px;
  }
  
  .muni-intro-article-text p {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
   .muni-intro-article-amount-conatiner {
      padding: 150px 90px 150px 90px;
  } 

   .muni-intro-articles {
    height: 250px;
    width: 250px;
  }
}

@media (min-width: 1280px) {

 .muni-intro-articles {
    height: 300px;
    width: 300px;
    padding: 40px;
  }

  .muni-intro-article-amount-conatiner {
    max-width: 1200px;
}

.muni-intro-article-amount-conatiner {
  padding: 150px 0px 150px 0px;
  }
  
.muni-intro-article-text p {
    font-size: 18px;
  }
}

@media (min-width: 1500px) {
.muni-intro-article-amount-conatiner {
    max-width: 1400px;
    padding: 150px 90px 150px 90px;

}
}
