.muni-category-button {
  background: linear-gradient(to right, rgb(255 104 99), rgb(246, 90, 85));
  background-color: rgb(255 104 99);
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  padding: 2px 13px;
  border: 0px solid #000;
  border-radius: 33px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  height: 30px;
}

.muni-button {
  background: linear-gradient(to right, rgb(255 104 99), rgb(246, 90, 85));
  background-color: rgb(255 104 99);
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  padding: 2px 13px;
  border: 0px solid #000;
  border-radius: 33px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
}

.muni-button span {
  margin-right: 16px;
  display: block;
  width: 29px;
  height: 29px;

  background-repeat: no-repeat;
  background-size: contain;
}

.muni-button:hover {
  background-color: rgb(245 152 149);
  transform: scale(1.03);
}

.muni-button-sub {
  background: linear-gradient(to right, #7c547c, #603960);
  background-color: #603960;
}

.muni-button-sub:hover {
  background: linear-gradient(to right, #916991, #7c547c);
  background-color: #7c547c;
}
.muni-button-cancel {
  background: linear-gradient(to right, #838383, #838383);
  background-color: #838383;
}

.muni-button-cancel:hover {
  background: linear-gradient(to right, #838383, #838383);
  background-color: #838383;
}

.muni-button:active {
  transform: scale(0.95);
}

.muni-cat-dot {
  height: 7px;
  width: 7px;
  background-color: #df6158;
  border-radius: 10px;
  margin-right: 5px;
  margin-left: -5px;
}

@media (max-width: 968px) {
  .muni-category-button {
    font-size: 12px;
    font-weight: 700;
    padding: 2px 4px;
    margin: 1px 3px;
    border-radius: 8px;
    width: fit-content;
  }
}
