
.muni-landingFooter {
  background-color: #402640;
  min-height: fit-content;
  min-width: 100vw;
  position: relative;
  padding: 40px 30px 50px 30px;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.muni-footer-top {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.muni-landingFooter h4 {
  font-weight: 600;
}

.mini-landingFooter-logo-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.muni-landingFooter-aboutUs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.muni-landingFooter-aboutUs p, .muni-footer-bottom p {
  font-size: 13px;
  line-height: 1.5;
}

.muni-landingFooter-socialmedia-Container{
  display: flex;
  gap: 10px;
}

.muni-landingFooter-socialmedia-Container img {
  width: 30px;
  height: 30px;
  cursor: pointer;

}

.muni-footer-line {
  /* position: absolute; */
  /* bottom: 25%; */
  
  background-color: #fff;
  height: 0.5px;
  display: flex;
}

.muni-footer-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}


.muni-footer-bottom-contact {
  display: flex;
  align-items: center;
  gap: 20px;
}

.muni-footer-bottom-contact p {
    cursor: pointer;
}

@media (min-width: 768px) {

  .muni-footer-bottom {
  flex-direction: row;
}

  .muni-landingFooter-aboutUs {
    flex-direction: row;
    align-items: flex-end;
  }

  .muni-landingFooter-aboutUs p {
    max-width: 50%;
  }
}

@media (min-width: 1280px) {
.muni-landingFooter h4 {
  font-weight: 500;
  font-size: 20px;
}

    .muni-landingFooter-aboutUs p {
    max-width: 35%;
  }
.muni-landingFooter {
    padding: 80px;
}

}

@media (min-width: 1500px) {
    .muni-landingFooter-aboutUs p {
    max-width: 500px;
  }

  .muni-landingFooter {
    padding: 100px;
}
}
