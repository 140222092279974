

.muni-signup-modal {
  z-index: 2;
  position: fixed;
  background-color: #402640;
  width: 100vw;
  height: 100vh;
  transition: all 0.3s;
  padding: 32px 32px 32px 32px;
}

.muni-signup-form-conatiner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.muni-signup-form-conatiner span,
input {
  cursor: pointer;
}

.muni-signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding-bottom: 6vh;
}

.muni-signup-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.muni-signup-modal-close {
  text-align: right;
}

.muni-signup-modal-form-container {
  align-self: center;
}

.muni-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: white;
}
.muni-login-label {
  color: white;
  font-weight: 400;
  font-size: 16px;
  padding: 5px;
}

.muni-login-label-link {
  text-decoration: underline;
  color: white;
  font-weight: 500;
}

.muni-signup-form-conatiner-register {
  padding-bottom: 0px;
}

.error {
  color: white;
}

@media (min-width: 768px) {
  .muni-signup-modal {
    padding: 40px 40px 40px 40px;
  }

  .muni-signup-modal-close img {
    height: 40px;
  }

  .muni-signup-register-button {
    font-size: 25px;
  }
  .muni-signup-login-button {
    font-size: 25px;
  }
}

@media (min-width: 1500px) {
  .muni-signup-form {
    padding-bottom: 10vh;
    gap: 40px;
  }
}

/* @media (max-width: 768px) {
  .muni-signup-register-button {
    display: none;
  }
  .muni-signup-login-button {
    display: none;
  }
} */
